import React, { useState, useEffect }  from "react"
import { useNavigate } from 'react-router-dom';
import RestClient from "../../RestClient"
import NavigationPane from "../../components/NavigationPane";

import Cookies from 'js-cookie';


const MainPage = () => {

  const [messageText, setMessageText] = useState();
  const [showLogin, setShowLogin] = useState();

  const navigate = useNavigate();

  const checkLogin = () => {
    console.log("in navigate")
    const session_url = 'status';
    var config = {
      method: 'get',
      url: session_url
    }
    RestClient(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      setMessageText("You are logged in as " + response.data.useremail)
      setShowLogin(false)
    })
    .catch(function (error) {
      console.log(error);
      setShowLogin(true)
      setMessageText("Please log in.")
    })
    
  };

  useEffect(() => {
    checkLogin()
    console.log("loaded component");
  }, [])

  const renderLogin = () => {
      if(showLogin===true){
         return(<button type="button" className="btn btn-primary" onClick={navigateToLogin}>
         Login
       </button>);
       }
       else{
           return(<div></div>)
       }
  }

  const navigateToLogin = () => {
    console.log("in navigate")
      navigate("/LoginForm")
   }
  
  return (

    <div>
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Welcome to Omega!</h3>
          <div className="form-group mt-3">
            <label>{messageText}</label>
          <div className="d-grid gap-2 mt-3">
         {renderLogin()}
          </div>
          
        </div>
        </div>
      </form>
    </div>

    </div>
  )
};

export default MainPage;
