import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css"
import { BrowserRouter, Routes, Route} from "react-router-dom"
import { useState } from "react"
import LoginForm from "./routes/LoginForm/LoginForm"
import MainPage from "./routes/MainPage/MainPage"
import NavigationPane from "./components/NavigationPane"
import NotificationSettings from "./routes/NotificationSettings/NotificationSettings"

function App() {
  return (
    <div>
    <NavigationPane></NavigationPane>    
    <BrowserRouter>
      <Routes>
        <Route path="/LoginForm" element={<LoginForm  />} />
        <Route path="/" element={<MainPage  /> } />
        <Route path="NotificationSettings" element={<NotificationSettings /> } />
      </Routes>
    </BrowserRouter>

    </div>
  )
}

export default App