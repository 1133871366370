import React, { useState, useEffect }  from "react"
import { useNavigate } from 'react-router-dom';
import RestClient from "../../RestClient"


const NotificationSettings = () => {

    const navigate = useNavigate();
    const CheckLogin = () => {
        const session_url = 'status';
        var config = {
          method: 'get',
          url: session_url
        }
        RestClient(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
          navigate("/LoginForm")
        })
      };

      CheckLogin()

  return (

    <div>
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Welcome to Omega</h3>
          <div className="form-group mt-3">
            <label>Notification Settings</label>
          
        </div>
        </div>
      </form>
    </div>

    </div>
  )
};

export default NotificationSettings;
