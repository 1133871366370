import React, { useState }  from "react"
import RestClient from "../../RestClient"
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {

  const navigate = useNavigate();

  const [emailValue, setEmailValue] = useState('');
  const handleEmailChange = (event) => {
    setEmailValue(event.target.value);
  };

  const [passwordValue, setPasswordValue] = useState('');
  const handlePasswordChange = (event) => {
    setPasswordValue(event.target.value);
  };

      const executeLogin = () => {
        const session_url = 'login';
        var config = {
          method: 'get',
          url: session_url,
          headers: {
            'Access-Control-Allow-Headers': 'Authorization',
            'Authorization': 'token'
          },
          auth: {
            username: emailValue,
            password: passwordValue
          }
        };
    
        RestClient(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          navigate(-1)
        })
        .catch(function (error) {
          console.log(error);
        });
      };

  return (
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Enter email"
              onChange={handleEmailChange}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              onChange={handlePasswordChange}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="button" className="btn btn-primary" onClick={executeLogin}>
              Submit
            </button>
          </div>
          <p className="forgot-password text-right mt-2">
            Forgot <a href="#">password?</a>
          </p>
        </div>
      </form>
    </div>
  )
};

export default LoginForm;
