import React, { useState }  from "react"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';



const NavigationPane = () => {

  const navBarTitle = "My Nav Bar"

  return (
            <Navbar expand="lg" className="bg-body-tertiary">
              <Container>
                <Navbar.Brand href="/">Omega</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                  <NavDropdown title="Settings" id="basic-nav-dropdown">
                      <NavDropdown.Item href="#action/3.1">Users</NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2">
                        Agents
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.3">Event Policies</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/NotificationSettings">
                        Notifications
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="#link">Link</Nav.Link>
                    <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                      <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2">
                        Another action
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="#action/3.4">
                        Separated link
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
        
        
  )
  
};

export default NavigationPane;
